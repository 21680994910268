import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { avatarProps } from 'app/packs/src/utils/user-helpers'
import { store } from 'store/index'
import { Avatar } from 'src/design-system'
import { baseItemClassName } from './utils'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { linkEvent } from '../utils'

export type UserItemProps = {
  id: string
}

export const UserItem = observer((props: UserItemProps) => {
  const { id } = props

  const user = store.users.byId(id)

  if (!user) return null

  const onClick = () => {
    trackEvent(linkEvent, { action: 'clicking user', trigger: 'click' })
  }

  return (
    <a href={user.profileUrl} onClick={onClick} className={baseItemClassName}>
      <div className="flex flex-row items-center gap-x-2">
        <Avatar {...avatarProps(user)} size="xs" />
        <span>{user.fullName}</span>
      </div>
      <span className="text-gray-400 text-sm">User</span>
    </a>
  )
})
