import * as React from 'react'
import { useModalContext } from 'src/design-system'
import { useOpenInviteModal } from 'components/invite-modal/utils'
import { useOpenNewTeamModal } from 'components/new-team-modal/utils'
import { store } from 'store/index'
import { CreateFormProps } from 'components/create-form'
import { CREATE_FORM_MODAL_ID } from 'components/create-form-modal/utils'
import { useOpenPositionModal } from 'components/position-modal/utils'
import { trackEvent } from '../../services/event-tracker'
import { linkEvent } from './utils'

export const useInterceptItemUrl = () => {
  const { openNewTeamModal } = useOpenNewTeamModal()
  const { openInviteModal } = useOpenInviteModal()
  const { openPositionModal } = useOpenPositionModal()

  const { openModal } = useModalContext()

  const getInitialUserId = React.useCallback((url: string) => {
    const [, paramString] = url.split('?')
    const urlParams = new URLSearchParams(paramString)
    const initialUserId = urlParams.get('initialUserId')

    return initialUserId
  }, [])

  const eventKey = linkEvent

  const shouldInterceptItemUrl = React.useCallback((itemUrl: string) => {
    return [
      '/teams/new',
      '/people/invite',
      '/checkins/new',
      '/actions/new',
      '/wins/new',
      '/feedback/new',
      '/feedback-request/new',
      '/positions/',
      '/logout',
    ].some((item) => itemUrl.includes(item))
  }, [])

  const interceptItemUrl = React.useCallback(
    (itemUrl: string): void | (() => void) => {
      if (itemUrl.includes('/logout')) {
        const logoutButton: HTMLButtonElement | null = document.querySelector(
          '.hidden-logout-button'
        )
        trackEvent(eventKey, { action: 'logout' })
        logoutButton?.click()
      }

      if (itemUrl.includes('/positions/')) {
        const positionId = itemUrl.split('/positions/')[1]
        trackEvent(eventKey, { action: 'open_position_modal' })
        openPositionModal({ positionId, source: 'quicksearch' })
      }

      if (itemUrl === '/teams/new') {
        trackEvent(eventKey, { action: 'open_new_team_modal' })
        openNewTeamModal()
      }

      if (itemUrl === '/people/invite') {
        trackEvent(eventKey, { action: 'open_invite_modal' })
        openInviteModal({ intent: 'quicksearch' })
      }

      if (itemUrl === '/checkins/new') {
        if (!store.currentUser) return

        const initialUserId = getInitialUserId(itemUrl)
        const initialUserIds = initialUserId ? [initialUserId] : undefined

        trackEvent(eventKey, { action: 'open_checkin_modal' })

        openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
          source: 'quicksearch',
          initialUserIds,
          user: store.currentUser,
          tabs: ['checkin'],
        })
      }

      if (itemUrl === '/actions/new') {
        if (!store.currentUser) return

        trackEvent(eventKey, { action: 'open_action_modal' })

        openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
          resourceType: 'Action',
          source: 'quicksearch',
          user: store.currentUser,
          tabs: ['action'],
        })
      }

      if (itemUrl.includes('/wins/new')) {
        if (!store.currentUser) return

        const initialUserId = getInitialUserId(itemUrl)
        const initialUserIds = initialUserId ? [initialUserId] : undefined

        trackEvent(eventKey, { action: 'open_win_modal' })

        openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
          resourceType: 'Win',
          source: 'quicksearch',
          user: store.currentUser,
          initialUserIds,
          tabs: ['win'],
        })
      }

      if (itemUrl.includes('/feedback/new')) {
        if (!store.currentUser) return

        const initialUserId = getInitialUserId(itemUrl)
        const initialUserIds = initialUserId ? [initialUserId] : undefined

        trackEvent(eventKey, { action: 'open_feedback_modal' })

        openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
          source: 'quicksearch',
          user: store.currentUser,
          initialUserIds,
          tabs: ['feedback'],
        })
      }

      if (itemUrl.includes('/feedback-request/new')) {
        if (!store.currentUser) return

        const initialUserId = getInitialUserId(itemUrl)
        const initialUserIds = initialUserId ? [initialUserId] : undefined

        trackEvent(eventKey, { action: 'open_feedback_request_modal' })

        openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
          source: 'quicksearch',
          user: store.currentUser,
          initialUserIds,
          startOnFromTab: true,
          tabs: ['feedback'],
        })
      }
    },
    [
      eventKey,
      getInitialUserId,
      openInviteModal,
      openModal,
      openNewTeamModal,
      openPositionModal,
    ]
  )

  return {
    interceptItemUrl,
    shouldInterceptItemUrl,
  }
}
