import { Tag } from 'src/design-system'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { observer } from 'mobx-react-lite'
import { LinkSimple } from '@phosphor-icons/react'
import * as React from 'react'
import { linkEvent } from '../utils'
import { baseItemClassName } from './utils'

export type PageItemProps = {
  title: string
  url: string
  alpha?: boolean
}

export const PageItem = observer((props: PageItemProps) => {
  const { title, url, alpha } = props

  const external = url.startsWith('http')

  const externalProps = external
    ? { target: '_blank', rel: 'nofollow noopener' }
    : {}

  const onClick = () => {
    trackEvent(linkEvent, { action: title, trigger: 'click' })
  }

  return (
    <a
      href={url}
      onClick={onClick}
      className={baseItemClassName}
      {...externalProps}
    >
      <div className="flex flex-row items-center gap-x-2">
        <span>{title}</span>
        {alpha && <Tag variant="warning">Alpha</Tag>}
      </div>
      <LinkSimple weight="bold" className="w-3.5 h-3.5 text-gray-400" />
    </a>
  )
})
