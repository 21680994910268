import { trackEvent } from 'app/packs/src/services/event-tracker'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { linkEvent } from '../utils'
import { baseItemClassName } from './utils'

export type TemplateItemProps = {
  id: string
}

export const TemplateItem = observer((props: TemplateItemProps) => {
  const { id } = props

  const template = store.teams.byId(id, { useDefaultFilter: false })

  if (!template) return null

  const onClick = () => {
    trackEvent(linkEvent, { action: 'clicking template', trigger: 'click' })
  }

  return (
    <a href={template.baseUrl} onClick={onClick} className={baseItemClassName}>
      <span>{template.name}</span>
      <span className="text-gray-400 text-sm">Template</span>
    </a>
  )
})
