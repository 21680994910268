import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { baseItemClassName } from './utils'
import { ArrowBendDownRight } from '@phosphor-icons/react'
import { useInterceptItemUrl } from '../use-intercept-item-url'
import { linkEvent } from '../utils'
import { trackEvent } from 'app/packs/src/services/event-tracker'

export type UserLinkItemProps = {
  id: string
  title: string
  url: string
}

export const UserLinkItem = observer((props: UserLinkItemProps) => {
  const { id, title, url } = props

  const user = store.users.byId(id)

  const { interceptItemUrl, shouldInterceptItemUrl } = useInterceptItemUrl()

  if (!user) return null

  const shouldIntercept = shouldInterceptItemUrl(url)
  const Tag = shouldIntercept ? 'button' : 'a'
  const tagProps = shouldIntercept
    ? {
        type: 'button' as const,
        onClick: () => {
          const action = user.isCurrentUser
            ? `User action for current user: ${title}`
            : `User action for another user: ${title}`
          trackEvent(linkEvent, { action, trigger: 'click' })
          interceptItemUrl(url)
        },
      }
    : { href: url }

  return (
    <Tag {...tagProps} className={baseItemClassName}>
      <div className="flex flex-row items-center gap-x-2">
        <ArrowBendDownRight
          weight="bold"
          className="w-3.5 h-3.5 text-gray-600"
        />
        <span>{title}</span>
      </div>
    </Tag>
  )
})
