import { Tag } from 'src/design-system'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { TeamStateVm } from 'components/team-state-dropdown/team-state-vm'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { linkEvent } from '../utils'
import { baseItemClassName } from './utils'

export type TeamItemProps = {
  id: string
}

export const TeamItem = observer((props: TeamItemProps) => {
  const { id } = props

  const team = store.teams.byId(id)

  if (!team) return null

  const vm = React.useMemo(() => {
    return new TeamStateVm()
  }, [])

  const { statusOptions } = vm

  const onClick = () => {
    trackEvent(linkEvent, { action: 'clicking team', trigger: 'click' })
  }

  return (
    <a href={team.baseUrl} onClick={onClick} className={baseItemClassName}>
      <div className="flex flex-row items-center gap-x-2">
        <span>{team.name}</span>
        <Tag variant={statusOptions[team.state].variant}>
          {statusOptions[team.state].title}
        </Tag>
      </div>
      <span className="text-gray-400 text-sm">Team</span>
    </a>
  )
})
