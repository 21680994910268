import { trackEvent } from 'app/packs/src/services/event-tracker'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { linkEvent } from '../utils'
import { baseItemClassName } from './utils'

export type SkillItemProps = {
  id: string
}

export const SkillItem = observer((props: SkillItemProps) => {
  const { id } = props

  const skill = store.skills.byId(id)

  if (!skill) return null

  const onClick = () => {
    trackEvent(linkEvent, { action: 'clicking skill', trigger: 'click' })
  }

  return (
    <a href={skill.url} onClick={onClick} className={baseItemClassName}>
      <div className="flex flex-row items-center gap-x-2">
        <span>{skill.name}</span>
      </div>
      <span className="text-gray-400 text-sm">Skill</span>
    </a>
  )
})
