import { SignOut } from '@phosphor-icons/react'
import * as React from 'react'
import { baseItemClassName } from './utils'
import cn from 'classnames'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { linkEvent } from '../utils'

export const LogoutItem = () => {
  const onClick = () => {
    const hiddenLogoutButton: HTMLButtonElement | null = document.querySelector(
      '.hidden-logout-button'
    )
    trackEvent(linkEvent, { action: 'Logout', trigger: 'click' })
    hiddenLogoutButton?.click()
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(baseItemClassName, '!text-red-500')}
    >
      Log out
      <SignOut weight="bold" className="text-red-500" />
    </button>
  )
}
