import { trackEvent } from 'app/packs/src/services/event-tracker'
import { useOpenPositionModal } from 'components/position-modal/utils'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { linkEvent } from '../utils'
import { baseItemClassName } from './utils'

export type PositionItemProps = {
  id: string
}

export const PositionItem = observer((props: PositionItemProps) => {
  const { id } = props

  const { openPositionModal } = useOpenPositionModal()

  const currentUser = store.nonNullCurrentUser
  const position = store.positions.byId(id)

  const onClick = () => {
    if (currentUser.org?.activeSubscription) {
      openPositionModal({ positionId: id })
      trackEvent(linkEvent, { action: 'open_position_modal' })
    } else {
      window.location.href = currentUser.isAdmin
        ? `/orgs/${currentUser.org?.slug}/billing/new`
        : '/'
    }
  }

  if (!position) return null

  return (
    <button className={baseItemClassName} onClick={onClick}>
      <div>{position.name}</div>
      <span className="text-gray-400 text-sm">Position</span>
    </button>
  )
})
