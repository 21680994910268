import { trackEvent } from 'app/packs/src/services/event-tracker'
import { observer } from 'mobx-react-lite'
import { Plus } from '@phosphor-icons/react'
import * as React from 'react'
import { useInterceptItemUrl } from '../use-intercept-item-url'
import { linkEvent } from '../utils'
import { baseItemClassName } from './utils'

export type ActionItemProps = {
  title: string
  url: string
}

export const ActionItem = observer((props: ActionItemProps) => {
  const { title, url } = props

  const { interceptItemUrl, shouldInterceptItemUrl } = useInterceptItemUrl()

  const external = url.startsWith('http')

  const shouldIntercept = shouldInterceptItemUrl(url)
  const Tag = shouldIntercept ? 'button' : 'a'
  const tagProps = shouldIntercept
    ? {
        type: 'button' as const,
        onClick: () => {
          trackEvent(linkEvent, { action: title, trigger: 'click' })
          interceptItemUrl(url)
        },
      }
    : {
        href: url,
        ...(external ? { target: '_blank', rel: 'nofollow noopener' } : {}),
      }

  return (
    <Tag {...tagProps} className={baseItemClassName}>
      <span>{title}</span>
      <Plus weight="bold" className="w-3.5 h-3.5 text-gray-400" />
    </Tag>
  )
})
