import * as React from 'react'
import { trackEvent } from '../../services/event-tracker'

type NoResultsProps = {
  query: string
}

export const NoResults = (props: NoResultsProps) => {
  const { query } = props

  React.useEffect(() => {
    trackEvent('$quick_search_no_results_found', { query })
  }, [query])

  return <div className="p-8 text-center text-gray-600">No results found</div>
}
