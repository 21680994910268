import { autocomplete } from '@algolia/autocomplete-js'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { render } from 'react-dom'
import { TeamItem } from './items/team-item'
import styles from './autocomplete.module.scss'
import { UserItem } from './items/user-item'
import { SkillItem } from './items/skill-item'
import { PageItem } from './items/page-item'
import { TemplateItem } from './items/template-item'
import { ActionItem } from './items/action-item'
import { UserLinkItem } from './items/user-link-item'
import { useInterceptItemUrl } from './use-intercept-item-url'
import { RootProvider } from 'src/design-system'
import GlobalModals from 'components/global-modals'
import { PositionItem } from './items/position-item'
import { LogoutItem } from './items/logout-item'
import { NoResults } from './no-results'
import { trackEvent } from '../../services/event-tracker'
import { linkEvent } from './utils'

type BaseItem = {
  id: string
  url?: string
}

type FilteredUserItem = {
  id: string
  url: string
  title?: string
}

// https://www.algolia.com/doc/ui-libraries/autocomplete/introduction/what-is-autocomplete/
export const Autocomplete = observer(() => {
  const containerRef = React.useRef(null)

  const { interceptItemUrl, shouldInterceptItemUrl } = useInterceptItemUrl()

  React.useEffect(() => {
    if (!containerRef.current || !interceptItemUrl) {
      return undefined
    }

    const currentUser = store.nonNullCurrentUser
    const hasActiveSubscription = !!currentUser.org?.activeSubscription
    const hasGrowPackage = currentUser.hasPackage('grow')
    const eventKey = linkEvent

    const search = autocomplete({
      container: containerRef.current,
      classNames: {
        form: styles.form,
        detachedSearchButton: 'hidden',
        item: styles.item,
        label: styles.label,
        submitButton: 'flex items-center',
        clearButton: styles.clearButton,
        detachedCancelButton: 'text-sm shadow-none',
      },
      renderNoResults: ({ render, state }, root) => {
        render(<NoResults query={state.query} />, root)
      },
      openOnFocus: true,
      defaultActiveItemId: 0,
      placeholder: 'Search teams, skills, people...',
      getSources: ({ query }: { query: string }) => {
        const filteredUsers = store.users.activeInvited.filter((user) =>
          user.fullName.toLowerCase().includes(query.toLowerCase())
        )

        // when only one result shows up, show user specific actions
        // only for grow packages, otherwise it's just 'view profile'
        if (
          filteredUsers.length === 1 &&
          hasGrowPackage &&
          store.users.activeInvited.length > 1
        ) {
          const getFilteredUserItems = (): FilteredUserItem[] => {
            const user = filteredUsers[0]

            const items: FilteredUserItem[] = [
              {
                id: user.id,
                url: user.profileUrl,
              },
              {
                id: user.id,
                title: `View ${
                  user.isCurrentUser ? 'your' : `${user.fname}'s`
                } profile`,
                url: user.profileUrl,
              },
            ]

            if (hasActiveSubscription) {
              if (user.isCurrentUser) {
                items.push(
                  ...[
                    {
                      id: user.id,
                      title: 'Add a win',
                      url: `/wins/new?initialUserId=${user.id}`,
                    },
                    {
                      id: user.id,
                      title: 'Add an action',
                      url: '/actions/new',
                    },
                    {
                      id: user.id,
                      title: 'Start a check-in',
                      url: '/checkins/new',
                    },
                  ]
                )
              } else {
                items.push(
                  ...[
                    {
                      id: user.id,
                      title: `Add a win for ${user.fname}`,
                      url: `/wins/new?initialUserId=${user.id}`,
                    },
                    {
                      id: user.id,
                      title: `Start a check-in with ${user.fname}`,
                      url: `/checkins/new?initialUserId=${user.id}`,
                    },
                    {
                      id: user.id,
                      title: `Give feedback to ${user.fname}`,
                      url: `/feedback/new?initialUserId=${user.id}`,
                    },
                    {
                      id: user.id,
                      title: `Request feedback from ${user.fname}`,
                      url: `/feedback-request/new?initialUserId=${user.id}`,
                    },
                  ]
                )
              }
            }

            return items
          }

          return [
            {
              sourceId: 'users',
              getItems: getFilteredUserItems,
              getItemUrl({ item }: { item: FilteredUserItem }) {
                return item.url
              },
              templates: {
                item: (props: { item: FilteredUserItem }) => {
                  if (props.item.title)
                    return (
                      <UserLinkItem
                        id={props.item.id}
                        title={props.item.title}
                        url={props.item.url}
                      />
                    )

                  return <UserItem id={props.item.id} />
                },
              },
            },
          ]
        }

        return [
          {
            sourceId: 'actions',
            getItems() {
              const links = [
                {
                  title: 'Give product feedback',
                  url: `https://airtable.com/shrMZXwkilntRE9BA?prefill_name=${store.currentUser?.fullName}&prefill_email=${store.currentUser?.email}`,
                },
              ]

              const growLinks = [
                { title: 'Start a check-in', url: '/checkins/new' },
                { title: 'Add an action', url: '/actions/new' },
                { title: 'Add a win', url: '/wins/new' },
                { title: 'Give feedback', url: '/feedback/new' },
                { title: 'Request feedback', url: '/feedback-request/new' },
              ]

              if (hasActiveSubscription && hasGrowPackage) {
                links.push(...growLinks)
              }

              const adminLinks = [
                { title: 'Create a new team', url: '/teams/new' },
                { title: 'Invite someone', url: '/people/invite' },
              ]

              if (store.currentUser?.isAdmin) links.push(...adminLinks)

              return links.filter((item) =>
                item.title
                  .toLowerCase()
                  .replace('-', '')
                  .includes(query.replace('-', '').toLowerCase())
              )
            },
            getItemUrl({ item: { url } }: { item: { url: string } }) {
              return url
            },
            templates: {
              item: (props: { item: { title: string; url: string } }) => (
                <ActionItem {...props.item} />
              ),
            },
          },
          {
            sourceId: 'pages',
            getItems() {
              const links: {
                title: string
                url: string | undefined
                alpha?: boolean
              }[] = [
                {
                  title: "View your org's skills",
                  url: store.currentUser?.org?.skillsPath,
                },
                { title: 'View library skills', url: '/skills' },
                { title: 'View library frameworks', url: '/frameworks' },
                { title: 'View library collections', url: '/collections' },
                {
                  title: 'View settings',
                  url: store.currentUser?.isAdmin
                    ? store.currentUser?.org?.editPath
                    : '/users/edit',
                },
                {
                  title: 'View your profile',
                  url: store.currentUser?.profileUrl,
                },
                {
                  title: 'View your team',
                  url: store.currentUser?.team?.baseUrl,
                },
                {
                  title: "View your org's homepage",
                  url: store.currentUser?.org?.basePath,
                },
                {
                  title: 'View product updates',
                  url: 'https://headwayapp.co/progression-updates',
                },
                {
                  title: 'View the Progression Help Center',
                  url: 'https://help.progressionapp.com/',
                },
              ]

              const growLinks = [
                {
                  title: "View your org's wins",
                  url: store.currentUser?.profileUrl + '/wins/org',
                },
                {
                  title: 'View your actions',
                  url: store.currentUser?.actionsUrl,
                },
                {
                  title: 'View your feedback',
                  url: `/?update_type=feedback_received&update_type=feedback_requested&user_id=${store.currentUser?.id}`,
                },
                {
                  title: 'View all activity',
                  url: `/feed?all=true`,
                },
              ]

              if (hasGrowPackage) links.push(...growLinks)

              const reflectionsEnabled =
                store.featureFlags.featureEnabled('reflections') &&
                hasGrowPackage

              if (reflectionsEnabled) {
                links.push({
                  title: 'View reflections',
                  url: '/reflections',
                  alpha: true,
                })
              }

              const adminLinks = [
                {
                  title: 'View all teams',
                  url: store.currentUser?.org?.basePath,
                },
                {
                  title: 'View all people',
                  url: store.currentUser?.org?.basePath + '/people',
                },
                { title: 'View teams', url: '/teams' },
              ]

              if (store.currentUser?.isAdmin) links.push(...adminLinks)

              return links.filter((item) =>
                item.title.toLowerCase().includes(query.toLowerCase())
              )
            },
            getItemUrl({ item: { url } }: { item: { url: string } }) {
              return url
            },
            templates: {
              item: (props: { item: { title: string; url: string } }) => (
                <PageItem {...props.item} />
              ),
            },
          },
          {
            sourceId: 'positions',
            getItems(): BaseItem[] {
              return store.positions.all
                .filter((position) =>
                  position.name.toLowerCase().includes(query.toLowerCase())
                )
                .map((position) => ({
                  id: position.id,
                  url: `/positions/${position.id}`,
                }))
            },
            getItemUrl({ item }: { item: BaseItem }) {
              return item.url
            },
            templates: {
              item: (props: { item: BaseItem }) => (
                <PositionItem id={props.item.id} />
              ),
            },
          },
          {
            sourceId: 'teams',
            getItems(): BaseItem[] {
              return store.teams.all
                .filter((team) =>
                  team.name.toLowerCase().includes(query.toLowerCase())
                )
                .map((team) => ({ id: team.id, url: team.baseUrl }))
            },
            getItemUrl({ item }: { item: BaseItem }) {
              return item.url
            },
            templates: {
              item: (props: { item: BaseItem }) => (
                <TeamItem id={props.item.id} />
              ),
            },
          },
          {
            sourceId: 'templates',
            getItems(): BaseItem[] {
              return store.teams.templates
                .filter((team) =>
                  team.name.toLowerCase().includes(query.toLowerCase())
                )
                .map((team) => ({ id: team.id, url: team.baseUrl }))
            },
            getItemUrl({ item }: { item: BaseItem }) {
              return item.url
            },
            templates: {
              item: (props: { item: BaseItem }) => (
                <TemplateItem id={props.item.id} />
              ),
            },
          },
          {
            sourceId: 'users',
            getItems(): BaseItem[] {
              return store.users.activeInvited
                .filter((user) =>
                  user.fullName.toLowerCase().includes(query.toLowerCase())
                )
                .map((user) => ({ id: user.id, url: user.profileUrl }))
            },
            getItemUrl({ item }: { item: BaseItem }) {
              return item.url
            },
            templates: {
              item: (props: { item: BaseItem }) => (
                <UserItem id={props.item.id} />
              ),
            },
          },
          {
            sourceId: 'skills',
            getItems(): BaseItem[] {
              return store.skills.forOrg
                .filter((skill) =>
                  skill.name.toLowerCase().includes(query.toLowerCase())
                )
                .map((skill) => ({ id: skill.id, url: skill.url }))
            },
            getItemUrl({ item }: { item: BaseItem }) {
              return item.url
            },
            templates: {
              item: (props: { item: BaseItem }) => (
                <SkillItem id={props.item.id} />
              ),
            },
          },
          {
            sourceId: 'misc',
            getItems(): BaseItem[] {
              return [
                {
                  id: 'Logout',
                  url: '/logout',
                },
              ].filter((item) =>
                item.id.toLowerCase().includes(query.toLowerCase())
              )
            },
            getItemUrl({ item }: { item: BaseItem }) {
              return item.url
            },
            templates: {
              item: (props: { item: BaseItem }) => {
                if (props.item.id === 'Logout') {
                  return <LogoutItem />
                }

                return null
              },
            },
          },
        ] as any
      },
      renderer: {
        createElement: React.createElement,
        Fragment: React.Fragment,
        render(children, root) {
          render(
            <RootProvider>
              <GlobalModals />
              {children}
            </RootProvider>,
            root
          )
        },
      },
      detachedMediaQuery: '',
      navigator: {
        // enter
        navigate({ itemUrl, item }) {
          if (shouldInterceptItemUrl(itemUrl)) interceptItemUrl(itemUrl)
          else {
            trackEvent(eventKey, { action: item.title, trigger: 'enter' })
            if (itemUrl.includes('https://')) {
              const windowReference = window.open(itemUrl, '_blank', 'noopener')

              if (windowReference) {
                windowReference.focus()
              }
            } else {
              window.Turbo.visit(itemUrl)
            }
          }
        },
        // cmd enter or ctrl enter
        navigateNewTab({ itemUrl, item }) {
          if (shouldInterceptItemUrl(itemUrl)) interceptItemUrl(itemUrl)
          else {
            trackEvent(eventKey, {
              action: item.title,
              trigger: 'cmd/ctrl enter',
            })
            const windowReference = window.open(itemUrl, '_blank', 'noopener')

            if (windowReference) {
              windowReference.focus()
            }
          }
        },
        // shift enter
        navigateNewWindow({ itemUrl, item }) {
          if (shouldInterceptItemUrl(itemUrl)) interceptItemUrl(itemUrl)
          else {
            trackEvent(eventKey, { action: item.title, trigger: 'shift enter' })
            window.open(itemUrl, '_blank', 'noopener')
          }
        },
      },
    })

    return () => {
      search.destroy()
    }
  }, [interceptItemUrl, shouldInterceptItemUrl])

  return <div ref={containerRef} />
})
